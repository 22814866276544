<template>
  <div id="contentUs">
    <t-header :nav-light="false"></t-header>
    <section class="bg-half bg-light d-table w-100" style="background: url('media/images/images/contact-detail.jpg') center center;">
      <div class="bg-overlay bg-overlay-white"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title">Üyelik Sözleşmesi</h4>
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item"><a @click="$router.push({name:'user.index'})">{{$t('index.home')}}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Üyelik Sözleşmesi</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>  <!--end col-->
        </div><!--end row-->
      </div> <!--end container-->
    </section><!--end section-->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->

    <!-- questions section begin -->
    <div class="questions-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-12">
              <h1 class="turuncu-baslik">Üyelik Sözleşmesi</h1>
              <strong>1. TARAFLAR</strong><br>
              <br>
              İşbu sözleşme ve sözleşmeyle atıfta bulunulan ve sözleşmenin ayrılmaz bir parçası olan belgelerden ve eklerden (EK-1 Gizlilik Politikası,) ve (EK-2 Nap Puan Kullanım Koşulları) oluşan işbu Üyelik Sözleşmesi (Hepsi birlikte 'Sözleşme' olarak anılacaktır), Napolyon Reklam ve Danışmanlık Hizmetleri Tic. A.Ş. (Bundan böyle kısaca 'Napolyon.com' olarak anılacaktır) ile Napolyon.com sitesine işbu Sözleşme' de belirtilen koşullar dahilinde kayıt olan Üye arasında, Üye'nin elektronik olarak onay vermesi ile karşılıklı olarak kabul edilerek üyenin onay verme tarihinde yürürlüğe girmiştir. <br>
              <br>
              <strong>2. TANIMLAR</strong><br>
              <br>
              İletişim Kanalları: Üye' nin Site' ye üye olduktan sonra Mesajları izlediği cep telefonu, sabit telefon, e-posta ve posta adresi gibi ve fakat bunlarla sınırlı olmaksızın her türlü iletişim mecralarını<br>
              <br>
              İzinli Pazarlama Hizmeti: Müşteri ile yapılan anlaşma sonucunda Mesajların Üye' ye İletişim Kanalları’ndan iletilmesi ve Üye'nin gelir oranının  Nap Puanlar'ı doğrultusunda hesaplanarak, Üye'nin Üye hesabına iletilmesine yönelik olarak işbu Sözleşme'de belirtilen koşullar dahilinde Napolyon.com tarafından sağlanan hizmeti; <br>
              <br>
              Mesaj(lar): Müşteriler'in, Üyeler'e pazarlama, araştırma, kampanya vesair amaçlarla gönderecekleri, Üyeler'in Site'ye üye olurken Müşteriler tarafından kendilerine gönderilmesine izin verdikleri tüm mesajları; <br>
              <br>
              Müşteri(ler): Sundukları ürün ya da hizmetlerle ilgili olarak Üyeler'e pazarlama, araştırma, kampanya, benzer  vesair amaçlarla Mesaj gönderilmesi veya gösterilmesi suretiyle İzinli Pazarlama Yönetimi Hizmeti'nden ve izinli veri tabanından aydalanmak üzere Napolyon.com ile anlaşma yapan gerçek ve tüzel kişileri; <br>
              <br>
              Ödeme Sistemi: Üyeler'in işbu Sözleşme içerisinde tanımlı işlemleri gerçekleştirmeleri sonucunda; Üye hesabında biriken Nap Puanlar karşılığında Napolyon.com'un  “Nap Puanlarım Sayfasından görüntülenecek miktarda ödenmesini ve/veya bahsi geçen Nap Puanlar kullandırılarak Napolyon.com ile anlaşmalı kurumlarda sunulan hizmetlerden yararlanmasının sağlandığı ve Site'nin ”Nasıl Kazanırım? Ve Nap Puan Nedir?” bölümlerinde detaylı olarak açıklanan sistemi; <br>
              <br>
              Nap Puan(lar): Üyeler’in tüm İletişim Kanalları’ndan gördükleri, Mesajlar başta olmak üzere, Site’de sunulan anket, forum gibi ürünler üzerinden kazandıkları puanları; <br>
              <br>
              Napolyon.com Hizmetleri ('Kısaca Hizmet(ler)'): Site içerisinde Üyeler'in işbu Sözleşme içerisinde tanımlı olan iş ve işlemlerini gerçekleştirmelerini sağlamak amacıyla Napolyon.com tarafından ortaya konulan İzinli Pazarlama Hizmeti dahil tüm uygulamaları; <br>
              <br>
              Site: www.Napolyon.com isimli alan adından ve bu alan adına bağlı alt alan adlarından oluşan web sitesi ve bu site ile bağlantılı olarak oluşturulan katma değerli hizmetlerin sunulduğu mecraları; <br>
              <br>
              Sisteme Erişim Araçları: Üye'nin, Üye Hesabı Yönetim Sayfası ve Napolyon.com Hizmetleri'ne erişimini sağlayan kullanıcı adı, parola ve/veya şifre v.b. gibi yalnızca Üye tarafından bilinen bilgileri; <br>
              <br>
              Üye(ler): Siteye Napolyon.com tarafından belirlenen yöntemlerle kayıt olan ve Napolyon.com tarafından üyeliği onaylanan, Site'de sunulan Hizmetler'den işbu Sözleşmede belirtilen koşullar dahilinde yararlanan kişi(ler)i; <br>
              <br>
              Üye Hesabı Yönetim Sayfası: Üye'nin yalnızca Sisteme Erişim Araçlarını kullanarak ulaştığı üyeliğine ilişkin iş ve işlemlerde bulunduğu kendisine özel web sayfasını ifade etmektedir. <br>
              <strong>
                <br>
                3. Sözleşme'nin Konusu ve Kapsamı
              </strong><br>
              <br>
              İşbu Sözleşme'nin konusu, Üye'ye yönelik olarak Napolyon.com tarafından Site'de mevcut olan ve/veya ileride sunulacak tüm Hizmetler'in, bu Hizmetler'den yararlanma şartlarının ve tarafların hak ve yükümlülüklerinin tespitidir. İşbu Sözleşme'nin kapsamı işbu Sözleşme ve ekleri ile Site içerisinde yer alan kullanıma, üyeliğe ve Hizmetler'e ilişkin Napolyon.com tarafından yapılmış olan bilcümle uyarı, yazı ve açıklama gibi beyanlardır. Üye, işbu Sözleşme hükümlerini kabul etmekle, Site içinde yer alan kullanıma, üyeliğe ve Hizmetler'e ilişkin Napolyon.com tarafından açıklanan her türlü beyanı da kabul etmiş olmaktadır. Üye bahsi geçen beyanlarda belirtilen her türlü hususa uygun davranacağını kabul, beyan ve taahhüt eder. <br>
              <br>
              <strong>4. Üyelik ve Hizmet Kullanımı Şartları </strong><br>
              <br>
              a) Site'nin ilgili bölümünden üye olmak isteyen kişinin Siteye üye olmak için gerekli bilgilerini verilmesi, bilgilerin doğruluğunun onaylanması, bilgilerin gönderilmesi suretiyle kayıt işleminin yaptırılması ve Napolyon.com tarafından kayıt işleminin onaylanması ile tamamlanır. Üyelik işlemi tamamlanmadan işbu Sözleşmede tanımlanan üye olma hak ve yetkisine sahip olunamaz. <br>
              <br>
              b) Siteye üye olabilmek için Napolyon.com tarafından geçici olarak üyelikten uzaklaştırılmış veya üyelikten süresiz yasaklanmış olmamak gerekmektedir. Yukarıda belirtildiği gibi Napolyon.com tarafından işbu Sözleşme'nin 5.1 maddesi uyarınca geçici olarak üyelikten uzaklaştırılmış veya üyelikten süresiz yasaklanmış olan kişilerin Site kayıt işlemlerini tamamlamış olmaları Site üyesi olmaları sonucunu doğurmayacaktır. <br>
              <br>
              c) Siteye üye olmak isteyen kişi, 6563 saylı Elektronik Ticaretin Korunması Hakkında Kanunun sadece ilgili maddeleriyle sınırlı olmak üzere ve ilgili diğer tüm mevzuata uygun olarak, işbu sözleşmenin konusu tüm haberleşmelerin yapılacağına dair bilgilendirilerek ve reddetme imkânı sağlanarak Napolyon.com ile paylaştığını, kendisi ile önceden izin alınmaksızın aynı veya benzer mal ya da hizmetlerle ilgili pazarlama, tanıtım, değişiklik ve bakım hizmetleri, ticari iletişim ve haberleşme yapılabileceğini, üyelik bilgilerinde kendisine sorulan soruların ayrıntılarının her türlü veri tabanında saklanmasını kabul ettiğini, kişisel verilerinin 3.kişiler ile paylaşılmasını kabul ettiğini beyan ve taahhüt eder. İşbu kabul taahhüt beyanı Üyeliğe onay verildikten devam edecek olup ve üyelik herhangi bir suretle sona ermedikçe devam edecektir. <br>
              Sözleşmedeki her hangi bir hakkın Napolyon .com tarafından hiç veya belirli bir süre kullanılmaması Napolyon.com tarafından bu haktan vazgeçildiği anlamına gelmeyecektir.<br>
              <br>
              <strong>5. Hak ve Yükümlülükler </strong><br>
              <br>
              <em>5.1. Üye'nin Hak ve Yükümlülükleri </em><br>
              <br>
              a) Üye, üyelik prosedürlerini yerine getirirken, Site'deki Hizmetler'den faydalanırken ve Site'deki Hizmetler'le ilgili herhangi bir işlemi yerine getirirken işbu Sözleşme içerisinde yer alan tüm şartlara, Site'nin ilgili yerlerinde belirtilen kurallara ve yürürlükteki tüm mevzuata, dürüstlük ve iyi niyet kuralları ile bu sözleşme amacına uygun hareket edeceğini, işbu sözleşmede düzenlenen tüm şart ve kuralları anladığını ve onayladığını kabul eder. <br>
              <br>
              b) Üye, Napolyon.com'un yürürlükteki emredici mevzuat hükümleri gereğince resmi makamlara açıklama yapmakla yükümlü olduğu durumlarda, resmi makamlarca usulü dairesinde bu bilgilerin talep edilmesi halinde, Napolyon.com'un Üyeler'e ait gizli/özel/ticari bilgileri resmi makamlara açıklamaya yetkili olacağını ve bu sebeple kendisinden her ne nam altında olursa olsun tazminat talep edilemeyeceğini kabul eder. <br>
              <br>
              c) Üye, Napolyon.com'un işbu Sözleşme konusu Hizmetler'i ifasına yönelik olarak ve/veya bu amaçlarla benzer hizmetlerin ifası için anlaşma akdettiği iş ortakları ile vesair nam altındaki 3. kişilere, Napolyon.com'un Üyeler'e ait üyenin izin verdiği  bilgileri açıklamaya yetkili olacağını ve bu sebeple kendisinden her ne nam altında olursa olsun tazminat talep edilemeyeceğini kabul eder. <br>
              <br>
              d) Üye'nin, Napolyon.com tarafından sunulan Hizmetler'den yararlanabilmek amacıyla kullandığı Sisteme Erişim Araçları'nın (kullanıcı ismi, şifre v.b) güvenliği, saklanması, üçüncü kişilerin bilgisinden uzak tutulması, kullanılması durumlarıyla ilgili hususlar tamamen Üye'nin kendi sorumluluğundadır. Üye'nin, Sisteme Giriş Araçları'nın güvenliği, saklanması, üçüncü kişilerin bilgisinden uzak tutulması, kullanılması gibi hususlardaki tüm ihmal ve kusurlarından dolayı Üye'nin ve/ veya üçüncü kişilerin uğradığı veya uğrayabileceği zararlardan Napolyon.com'un doğrudan veya dolaylı herhangi bir sorumluluğu yoktur. <br>
              <br>
              f) Üye, Site dahilinde kendisi tarafından sağlanan bilgilerin ve içeriklerin doğru ve hukuka uygun olduğunu kabul ve taahhüt etmektedir. Napolyon.com, Üye tarafından Napolyon.com'a iletilen veya Site üzerinden kendisi tarafından yüklenen, değiştirilen ve sağlanan bilgilerin ve içeriklerin doğruluğunu araştırma; bu bilgi ve içeriklerin güvenli, doğru ve hukuka uygun olduğunu taahhüt ve garanti etmekle yükümlü ve sorumlu olmadığı gibi, söz konusu bilgi ve içeriklerin yanlış veya hatalı olmasından dolayı ortaya çıkacak hiçbir zarardan da sorumlu tutulamaz. <br>
              <br>
              e) Üye, “Nap Puanlarım Sayfası”nda yapılan tüm işlemlerin münhasıran ve müstakilen kendisi tarafından gerçekleştirildiğini kabul, beyan ve tahhüt eder. Üye, Napolyon.com'a verdiği İletişim Kanalları'nın yalnızca kendisine ait ve yalnızca kendi kontrolü altındaki İletişim Kanalları olduğunu ve bu İletişim Kanalları'nı aktif olarak kullandığını, başkasına devretmediğini, taahhüt eder. Üye İletişim Kanalları'nın Üye'nin kendisine ait olmamasından veya İletişim Kanalları'nı aktif olarak kullanmamasından dolayı Napolyon.com'un uğrayabileceği her türlü maddi ve manevi zararı tazminle yükümlü olacaktır. Üye'nin kendisi için oluşturulmuş Üye Hesabı Yönetim Sayfasını başkasına devretmesi veya kullanıma açması halinde Üye; Napolyon.com'un tek taraflı olarak ve ihbarda bulunmadan işbu Sözleşmeyi feshetme ve Üye'nin üyeliğine son verme hakkı bulunduğunu kabul ve beyan eder. Üye, Napolyon.com'un yazılı onayı olmadan işbu Sözleşmeyi veya bu Sözleşme'nin kapsamındaki hak ve yükümlülüklerini kısmen veya tamamen herhangi bir üçüncü kişiye devredemez. <br>
              <br>
              f) Napolyon.com'un sunduğu Hizmetler'den yararlananlar, Üye ve Siteyi kullananlar, Site dahilinde veya üzerinden yalnızca hukuka uygun amaçlarla işlem yapabilirler. Üye'nin, Site dahilinde veya üzerinden yaptığı her işlem ve eylemdeki hukuki ve cezai sorumluluk kendisine aittir. Üye, Napolyon.com ve/veya başka bir üçüncü şahsın ayni veya şahsi haklarına, malvarlığına tecavüz teşkil edecek nitelikteki Site dahilinde bulunan resimleri, metinleri, görsel ve işitsel imgeleri, video klipleri, dosyaları, veri tabanlarını, katalogları ve listeleri çoğaltmayacağını, kopyalamayacağını, dağıtmayacağını, işlemeyeceğini, bu eylemleri ile Napolyon.com ile doğrudan ve/veya dolaylı olarak rekabete girmeyeceğini kabul ve taahhüt etmektedir. Üye'nin işbu Sözleşme hükümlerine ve hukuka aykırı olarak gerçekleştirdiği Napolyon.com ve Napolyon.com Hizmetleri ile bağlantılı faaliyetleri nedeniyle üçüncü kişilerin uğradıkları veya uğrayabilecekleri zararlardan dolayı Napolyon.com doğrudan ve/veya dolaylı olarak hiçbir şekilde sorumlu tutulamaz. <br>
              <br>
              g) Site'de ve Site üzerinden bağlantı ( link ) verilen internet sitelerinde, Üye de dahil olmak üzere üçüncü kişiler tarafından yayınlanan içeriklerden dolayı Napolyon.com'un, Napolyon.com çalışanlarının ve yöneticilerinin ve tüzel kişiliğinin sorumluluğu bulunmamaktadır. Herhangi bir üçüncü kişi tarafından sağlanan ve yayınlanan bilgilerin, içeriklerin, görsel ve işitsel imgelerin doğruluğu ve hukuka uygunluğunun taahhüdü bütünüyle bu eylemleri gerçekleştiren kişilerin sorumluluğundadır. Napolyon.com, Üye de dahil olmak üzere üçüncü kişiler tarafından sağlanan hizmetlerin ve içeriklerin güvenliğini, doğruluğunu ve hukuka uygunluğunu taahhüt ve garanti etmemektedir. <br>
              <br>
              h) Üye ve Napolyon.com birbirinden bağımsız taraflardır. İşbu Sözleşme'nin Üye ve Napolyon.com tarafından onaylanması ve uygulanması sonucunda, aralarında ortaklık, temsilcilik veya işçi-işveren, mal ve hizmet sağlayıcı-tüketici, vekaletilişkisi doğmaz. <br>
              <br>
              i) Napolyon.com'un yazılı olarak önceden verilmiş izni olmadan, Üye'nin, Site üzerinden yapacakları herhangi bir işlemde başka bir web sitesine, bir web sitesinin içeriğine veya ağ üzerindeki herhangi bir veriye link vermesi yasaktır. Böyle bir durumda işbu tüm hukuki ve cezai sorumluluk üyeye ait olup, Napolyon.com’un tüm zarar ve ziyanını karşılamayı kabul ve taahhüt eder.<br>
              <br>
              j) Üye ‘Napolyon.com sitesi aracılığı ile göndereceği’ ya da ‘Napolyon.com davet sistemi kullanarak göndereceği elektronik postalar ile üçüncü kişilerce istenmeyen elektronik posta iletişimi olarak nitelendirilebilecek yoğunlukta bir iletişimde bulunmayacağını, işbu Sözleşme kapsamında üçüncü kişilere göndereceği referans mesajlarının hukuka ve ahlaka aykırı unsurlar içermeyeceğini kabul, beyan ve taahhüt eder <br>
              Üye, işbu madde hükmüne aykırı olarak yapacağı tüm elektronik posta iletişiminin sorumluluğunun kendisine ait olduğunu, her ne sebeple olursa olsun bu iletişimlerden kaynaklı olarak Napolyon.com'a bir dava ve talep yöneltilmesi halinde; bu dava ve talep'in Napolyon.com tarafından kendisine ihbar edilebileceğini, Napolyon.com'un savunma yapması için gerekli olan her türlü bilgi ve içeriği kendisine savunma için gerekli yasal sürelerin geçirilmemesini teminen derhal sağlayacağını, bahsi geçen dava ve talep sonucunda Napolyon.com'un herhangi bir zarara uğraması durumunda bahsi geçen zararın Napolyon.com tarafından, tazminat hakları saklı kalmak kaydıyla, kendisine rücu ettirilebileceğini kabul, beyan ve taahhüt eder. . Üye iletişime geçtiği tüm 3. kişilerin isim, telefon vesair tüm iletişim bilgilerinin kendisi tarafından mevzuata ve dürüstlük kurallarına uygun olarak tedarik edildiğini, bu bilgilerin kişisel verilerin paylaşımı ile ilgili Elektronik Ticaretin Düzenlenmesi Hakkında Kanun ve kişisel verilerin korunması hakkında yapılmış ya da yapılacak her türlü yasal düzenleme ve ilgili tüm sair mevzuat kapsamında ilgili kişilerden tüm izinlerin alındığını beyan kabul ve taahhüt eder.<br>
              <br>
              Üye, iletişime geçilecek kişilerin iletişim izinlerinin düzenli olarak güncellendiğini, bu güncellemeleri şirket ve Napolyon’a bildirildiğini, ilgili tüm bu izinlerin alınmamış olması veya alınmasına rağmen veya Kanun kapsamında anılan hizmetlerin verilmesi nedeniyle veya bağlantılı olarak anılan kişilerden veya resmi mercilerden gelebilecek her türlü talep, şikayet, iddia ve sorun vb ile ilgili tüm sorumluluğun münhasıran kendisine ait olduğunu, bu bağlamda Şirket ve Napolyon’un maddi, manevi, hukuki ve cezai anlamda anılan 3. Kişilere, resmi mercilere ve üyeye karşı herhangi bir sorumluluğu olmayacağını,   Napolyon.com tüm bunlardan umumi surette b’eri kıldığını,  bu nedenlerle veya bunlarla bağlantılı olarak şirket ve Napolyon.com anılan 3. Kişilere veya resmi mercilere tazminat, para cezası, harç vesair nam altında herhangi bir ödeme yapmak durumunda olması halinde Napolyon.com’un ödediği tüm tutarları Napolyon.com’a aynen tazmin edeceğini ve ayrıca Napolyon.com’un  bu nedenle uğradığı/uğrayacağı tüm zararlarını Napolyon.com’un ilk talebi ile birlikte Napolyon.com’a derhal nakden ve defaten tazmin edeceğini gayri kabili rücu olarak kabul, beyan ve taahhüt eder. <br>
              Üye'nin işbu maddeye aykırı hareket etmesi sebebiyle Napolyon.com işbu Sözleşmeyi herhangi bir bildirimde bulunmadan tek taraflı olarak feshetme, üyeliği askıya alma veya iptal etme hakkını saklı tutmaktadır. Üye işbu madde hükmüne aykırı davranması durumunda üyelik sıfatına binaen kazanmış olduğu ve/veya kazanacağı tüm haklardan Napolyon.com lehine sarfinazar ettiğini peşinen kabul, beyan ve taahhüt etmektedir.<br>
              k) Üye'nin Napolyon.com'a ilişkin referans mesajlarını ve Mesajları, istenmeyen elektronik posta gönderen elektronik posta listeleri tarafından gönderilmiş gibi gösterilmesine ilişkin fiillerde bulunması veya böyle bir duruma sebebiyet vermesi halinde Napolyon.com Üye'nin üyeliğini hiçbir ihtar ve ihbara gerek kalmadan sona erdirme hakkını saklı tutmaktadır. Üye üyelik hak ve yetkilerini başkalarına devretmemeyi, üyeliğe ilişkin olarak herhangi bir manipülasyonda ve yanıltıcı fiilde bulunmamayı kabul, beyan ve taahhüt etmektedir. <br>
              <br>
              l) Üye dilediği zaman <a href="/Icerik/BizeUlasin">http://napolyon.com/Icerik/BizeUlasin</a> adresinden ücretsiz bir şekilde sebeple üyelikten ayrılabilir. <br>
              <br>
              <em>5.2. Napolyon.com'un Hak ve Yükümlülükleri </em><br>
              <br>
              a) Napolyon.com, Site'de sunulan Hizmetler'i, koşulları, uygulamaları ve tüm içerikleri her zaman tek taraflı olarak değiştirebilme; Üye'nin sisteme yüklediği bilgileri ve içerikleri Üye de dahil olmak üzere üçüncü kişilerin erişimine kapatabilme ve silme hakkını saklı tutmaktadır. Napolyon.com, bu hakkını hiçbir bildirimde bulunmadan ve önel vermeden kullanabilir.  Sözleşmede yapılan değişiklikler Napolyon.com sitesinde ve Üye’lerin e-posta adreslerine yapılan gönderimler ile bildirilecektir. Güncel sözleşmenin takibinden Üye sorumludur. Herhangi bir anlaşmazsızlıkta 8.2 maddesindeki Sözleşme Değişiklikleri hükümleri geçerlidir.  Üye, Napolyon.com'un talep ettiği değişiklik ve/veya düzeltmeleri ivedi olarak yerine getirmek zorundadır. Napolyon.com tarafından talep edilen değişiklik ve/veya düzeltme istekleri gerekli görüldüğü takdirde Napolyon.com tarafından doğrudan yapılabilir. Napolyon.com tarafından talep edilen değişiklik ve/veya düzeltme taleplerinin, Üye tarafından zamanında yerine getirilmemesi sebebiyle doğan veya doğabilecek zararlar, hukuki ve cezai sorumluluklar tamamen Üye'ye aittir. <br>
              <br>
              b) Referans kolaylığı veya çeşitli sebepler nedeniyle Site içerisinde yalnızca Napolyon.com tarafından Napolyon.com kontrolünde olmayan bir takım web sitelerine veya içeriklere linkler verilebilir. Bu linkler bahsi geçen web sitesini veya içerik sahibini destekleyici mahiyette olmadığı gibi, web sitesi veya içerikte yer alan bilgilere yönelik herhangi bir türde bir beyan veya garanti niteliği de taşımamaktadır. Site üzerindeki linkler vasıtasıyla erişilen portallar, web siteleri, dosyalar ve içerikler, bu linkler vasıtasıyla erişilen portallar veya web sitelerinden sunulan hizmetler veya ürünler veya bunların içeriği hakkında Napolyon.com'un herhangi bir sorumluluğu yoktur. <br>
              <br>
              c) Napolyon.com, Site'de yer alan Üye bilgilerini veya Üye'nin açıkladığı diğer bilgileri, Hizmetler dışında da Üye güvenliği, kendi yükümlülüğünü ifa ve bazı istatistiki değerlendirmeler ve iç denetimler için dilediği biçimde kullanabilir, bunları bir veritabanı üzerinde tasnif edip muhafaza edebilir. Üye bu tür kullanımlara izin verdiğini kabul ve taahhüt eder.<br>
              <br>
              d) Napolyon.com, Site'nin işleyişine, genel kurallarına, genel ahlak kurallarına aykırı olan Mesaj, anket ve içerikleri istediği zaman ve şekilde erişimden kaldırabilir ve/veya bu Mesaj, anket veya içeriği giren Üye'nin üyeliğine herhangi bir ihbar yapmadan son verebilir. Üye Site'nin işleyişine, genel kurallarına, genel ahlak kurallarına aykırılık sebebiyle Mesaj, anket ve içeriklerinin erişimden kaldırılması ve üyeliğine herhangi bir ihbar yapılmaksızın son verilmesi durumunda hiçbir itirazda, tazminat ve benzer talepte bulunmayacağını kabul, beyan ve taahhüt eder. <br>
              <br>
              e) Napolyon.com, Site içerisinde ortaya konulan uygulamalar üzerinde Üyeler'in işbu Sözleşme içerisinde tanımlı olan iş ve işlemleri daha etkin şekilde gerçekleştirebilmelerini sağlamak üzere dilediği zaman Hizmetler'de değişiklikler ve/veya uyarlamalar yapabilir. Napolyon.com tarafından yapılan bu değişiklikler ve/veya uyarlamalarla ilgili Üye'nin uymakla yükümlü olduğu kural ve koşullar Napolyon.com tarafından ilgili Hizmet'in kullanımıyla ilgili açıklamaların bulunduğu web sayfasından Üyeler'e duyurulur. <br>
              <br>
              f) Napolyon.com istediği zaman, Site'de belirtmek suretiyle Hizmetler'i ücretli veya Nap Puan karşılığı olarak veya sair yollarla sağlayabilir veya bunlara ilişkin olarak dilediği değişiklikleri tek taraflı yapabilir. Site'de bildirimin yapılmasından itibaren söz konusu Hizmetler bildirilen şart ve koşullarda sağlanacak ve söz konusu Hizmetler'den değişiklikten önce faydalanan Üye'ye de değişiklikler yeni şart ve koşullar ile uygulanacaktır. <br>
              <br>
              <strong>6. Hizmetler </strong><br>
              <br>
              Napolyon.com Site'de, Üyeler'e işbu Sözleşme ile kapsamı ve içeriği belirlenen Hizmetler'i sağlayacaktır. Napolyon.com tarafından verilen Hizmetler'in temelinde Müşteriler ile izinli pazarlama ve anket çalışması anlaşmalarının yapılması, Üyeler'e Mesajlar'ın ve anketlerin İletişim Kanalları’ndan iletilmesi ve bunun için gerekli alt yapının hazırlanması, izinli pazarlama ve anket gelirlerinin hesaplanması ve işbu Sözleşme ile belirlendiği şekil ve koşullarla Üyeler'e iletilmesi bulunmaktadır. <br>
              <br>
              <em>6.1. İzinli Pazarlama Hizmeti </em><br>
              <br>
              a) Üye işbu Sözleşme hükümlerine uygun olarak izin verdiği kişisel verileri ve iletişim bilgileri vasıtasıyla kendisine yöneltilecek Mesajlar'a yönelik herhangi bir itirazda bulunamaz. <br>
              <br>
              b) Üye iletişim bilgileri Napolyon.com tarafından herhangi bir sınırlama olmadan kullanılabilir. Napolyon.com İzinli Pazarlama Hizmetini verebilmek için Müşterilerle reklam veren olarak anlaşabilir. Üye aksini beyan etmediği sürece; Napolyon.com, İzinli Pazarlama Hizmeti'nin yürütülmesi için Üye'nin kendisine açıkladığı tüm İletişim Kanallarını izinli pazarlama yapmak amacıyla kullanabilir. <br>
              <br>
              c) Üye, Napolyon.com tarafından sunulan İzinli Pazarlama Hizmeti sonucunda işbu Sözleşme hükümlerine uygun olarak hak kazandığı ödemelerin yapılabilmesi için IBAN numarasını içeren şahsi mevduat hesabını Napolyon.com'a bildirmek zorundadır. Üye'nin bildirdiği şahsi mevduat hesabı dışında veya Üye'nin kendisine ait şahsi bir mevduat hesabı bildirmemesi halinde Napolyon.com tarafından Üye'ye herhangi bir ödeme yapılamaz. Üye'nin; şahsi mevduat hesabı olmayan bir mevduat hesabı bildirmesi ya da şahsi mevduat hesabını yanlış veya eksik bildirmesi durumunda bütün sorumluluk Üye'ye aittir. Üye, Napolyon.com'un Üye'nin yanlış ve/veya eksik olarak bildirdiği mevduat hesabına ve/veya Üye'nin bildirdiği ancak şahsına ait olmayan mevduat hesabına ödeme yapmaması veya bu hesaplara zuhulen ödeme yapılması sebebiyle Napolyon.com'dan her ne nam ve surette olursa olsun herhangi bir talepte bulunmayacağını, kabul, beyan ve taahhüt eder. Napolyon.com, işbu surette Üye'nin yanlış veya eksik olarak bildirdiği hesaba yapılan ödeme sonucunda, Üye'ye karşı ödeme borcundan gayri kabili rücu olarak kurtulmuş olacaktır. Yapılan ödeme nedeniyle doğacak ayni, nakdi her türlü vergi, rüsum, harç vesair yükümlükler Üye'ye ait olup bunların ilgili kurumlara yatırıldığı Üye tarafından belgelendirilecektir.<br>
              Üye işbu ödemelerin  hiç veya zamanında yapılmaması nedeniyle Napolyon.com’un uğrayacağı tüm zararları nakden ve defaten Napolyon.com’un ilk talebi üzerine ödeyeceğini kabul ve taahhüt eder. <br>
              d) Üye'nin Sözleşme konusu Hizmetler'le ilgili olarak edindiği Nap Puanlar'ın kullanımına ilişkin koşullar işbu Sözleşme'nin ayrılmaz parçası olan “EK 2-Nap Puan Kullanım Koşulları”nda belirtilmiş olup Üye işbu sözleşmeyi kabul ile “EK – Nap Puan Kullanım Koşullarını” da kabul etmiş sayılacaktır.  <br>
              <br>
              e) Üye, işbu Sözleşme'ye uygun olarak kendisine yöneltilecek Mesaj içeriğinin Napolyon.com tarafından kullanılmasının izlenmesini engelleyecek herhangi bir fiilde bulunmamayı, buna uygun kurulacak denetim sistemine uygun davranmayı, işbu madde hükmüne aykırı hareket etmesi durumunda Napolyon.com tarafından kendisine herhangi bir ödeme yapılmayacağını ve bu konuda herhangi bir hak iddia etmeyeceğini kabul, beyan ve taahhüt eder. <br>
              <br>
              <br>
              <strong>7. Gizlilik Politikası </strong><br>
              <br>
              Napolyon.com, Site'de Üyeler'le ilgili bilgileri işbu Sözleşme'nin kapsamı dışında işbu Sözleşme'nin EK-1 bölümünde yer alan ve Sözleşme'nin ayrılmaz bir parçası olan 'Gizlilik Politikası' kapsamında kullanabilir. Napolyon.com Üyeler'e ait gizli bilgileri, işbu Sözleşme'nin kapsamı dışında ancak 'Gizlilik Politikası'nda belirtilen koşullar dahilinde üçüncü kişilere açıklayabilir veya kullanabilir. <br>
              <br>
              <strong>8. Diğer Hükümler </strong><br>
              <br>
              <em>8.1. Fikri Mülkiyet Hakları </em><br>
              <br>
              a) Site'nin (tasarım, metin, imge, html kodu ve diğer kodlar da dahil ve fakat bunlarla sınırlı olmamak kaydıyla) tüm unsurları (kısaca 'Napolyon.com'un Telif Haklarına Tabi Çalışmaları') Napolyon.com'a ait ve/veya Napolyon.com tarafından üçüncü bir kişiden alınan lisans hakkı altında kullanılmaktadır. Üyeler, Hizmetler'i, Napolyon.com bilgilerini ve Napolyon.com'un Telif Haklarına Tabi Çalışmaları'nı yeniden satamaz, paylaşamaz, dağıtamaz, sergileyemez veya başkasının Hizmetler'e erişmesine veya kullanmasına izin veremez, aksi takdirde lisans verenler de dahil üçüncü kişilerin uğradıkları zararlardan dolayı Napolyon.com'dan talep edilen tazminat miktarını, mahkeme masrafları ve avukatlık ücreti de dahil olmak üzere karşılamakla yükümlü olacaklardır. Üyeler Napolyon.com'un Telif Haklarına Tabi Çalışmaları'nı çoğaltamaz, dağıtamaz veya bunlardan türemiş çalışmalar yapamaz veya hazırlayamaz. <br>
              <br>
              b) Napolyon.com'un; Napolyon.com Hizmetleri, Napolyon.com bilgileri, Napolyon.com'un Telif Haklarına Tabi Çalışmaları'na, Napolyon.com ticari markalarına, Napolyon.com ticari görünümüne ve Site vasıtasıyla sahip olduğu her tür maddi ve fikri mülkiyet hakları da dahil tüm malvarlığı, ayni ve şahsi hak, ticari bilgi ve know-how'a yönelik tüm hakları saklıdır. <br>
              <br>
              <em>8.2. Sözleşme Değişiklikleri </em><br>
              <br>
              Napolyon.com, tamamen kendi takdirine bağlı ve tek taraflı olarak işbu Sözleşme'yi, veya herhangi bir hükmünü Hizmet koşullarını, uygulamaları ve bunlara ilişkin tüm içerikleri uygun göreceği herhangi bir zamanda Site'de ilan ederek tek taraflı olarak değiştirebilir. İşbu Sözleşme'nin değişen hükümleri, Site’de ilan edildikleri tarihte geçerlilik kazanacak, geri kalan hükümler aynen yürürlükte kalarak hüküm ve sonuçlarını doğurmaya devam edecektir. Üye, söz konusu değişikliği kabul etmemesi halinde, işbu değişikliğin Site'de ilan edildiği tarihten itibaren 7 (yedi) gün içinde keyfiyeti Napolyon.com'a yazılı olarak bildirip, Sözleşme'yi tek taraflı ve tazminatsız olarak, değişiklik tarihine kadar kazanılmış hakları saklı kalmak kaydıyla, feshedebilir. İşbu Sözleşme, Üye'nin tek taraflı beyanları ile değiştirilemeyeceği gibi, haklı nedenler dışında gerekçe gösterilmeksizin Üye tarafından tek taraflı olarak feshedilemez. <br>
              <br>
              <em>8.3. Mücbir Sebepler </em><br>
              <br>
              Hukuken mücbir sebep sayılan tüm durumlarda, Napolyon.com işbu Sözleşme ile belirlenen edimlerinden herhangi birini geç veya eksik ifa etme veya ifa etmeme nedeniyle yükümlü değildir. Bu ve bunun gibi durumlar, Napolyon.com için, gecikme, eksik ifa etme veya ifa etmeme veya temerrüt addedilmeyecek veya bu durumlar için Napolyon.com'dan herhangi bir nam altında tazminat talep edilemeyecektir. Mücbir sebep terimi, doğal afet, isyan, savaş, grev, iletişim sorunları, altyapı ve internet arızaları, elektrik kesintisi ve kötü hava koşulları, Napolyon.com'un gerekli bilgi güvenliği önlemleri almasına karşın Site'ye ve sisteme yapılan saldırılar da dahil ve fakat bunlarla sınırlı olmamak kaydıyla Napolyon.com'un makul kontrolü haricinde gelişen ve Napolyon.com'un gerekli özeni göstermesine rağmen önleyemediği kaçınılamayacak olaylar olarak yorumlanacaktır. <br>
              <br>
              <em>8.4. Uygulanacak Hukuk ve Yetki Kuralları</em><br>
              <br>
              İşbu Sözleşme'nin uygulanmasında, yorumlanmasında ve işbu Sözleşme dahilinde doğan hukuki ilişkilerin yönetiminde yabancılık unsuru bulunması durumunda Türk kanunlar ihtilafı kuralları hariç olmak üzere, Türk Hukuku uygulanacaktır. İşbu Sözleşme'den dolayı doğan veya doğabilecek her türlü ihtilafın hallinde İstanbul Merkez Mahkemeleri ve İcra Daireleri yetkilidir. <br>
              <br>
              <em>8.5. Napolyon.com Kayıtlarının Geçerliliği </em><br>
              <br>
              Üye, işbu Sözleşme'den doğabilecek ihtilaflarda Napolyon.com'un kendi veri tabanında, sunucularında tuttuğu elektronik ve sistem kayıtlarının, ticari kayıtlarının, defter kayıtlarının, mikrofilm, mikrofiş ve bilgisayar kayıtlarının muteber bağlayıcı, kesin ve münhasır delil teşkil edeceğini, Napolyon.com'u yemin teklifinden ber'i kıldığını ve bu maddenin. HMK 193. madde anlamında delil sözleşmesi niteliğinde olduğunu kabul, beyan ve taahhüt eder. <br>
              <br>
              <em>8.6. Yürürlük </em><br>
              <br>
              İşbu Sözleşme Üye'nin elektronik olarak onay vermesi ile karşılıklı olarak kabul edilerek yürürlüğe girmiş olup, ekleri ile birlikte uygulanacaktır. Ekler ile işbu Sözleşme hükümleri arasında çelişki olması halinde işbu Sözleşme hükümleri geçerli olacaktır. <br>
              <br>
              <strong>EK 1: Napolyon.com Gizlilik Politikası</strong><br>
              <br>
              Napolyon.com projesi için tahsis edilmiş www.napolyon.com alan adlı web sitesinin (kısaca "Site" olarak anılacaktır) tüm hakları Napolyon Reklam ve Danışmanlık Hizmetleri Ticaret Anonim Şirketi'ne (kısaca "Napolyon.com" olarak anılacaktır) aittir. Napolyon.com, Site üzerinden kullanıcılar tarafından kendisine elektronik ortamda iletilen kişisel bilgileri, üyeleri ile yaptığı "Napolyon.com Üyelik Sözleşmesi" ile üyeden alınan izinler belirlenen amaçlar ve kapsam dışında üçüncü kişilere açıklamayacaktır. <br>
              <br>
              Sistemle ilgili sorunların tanımlanması ve Site'de çıkabilecek sorunların ivedilikle giderilebilmesi için, Napolyon.com, gerektiğinde kullanıcıların IP adresini tespit etmekte ve bunu kullanmaktadır. IP adresleri, kullanıcıları genel bir şekilde tanımlamak ve kapsamlı demografik bilgi toplamak amacıyla da kullanılabilir. <br>
              <br>
              Napolyon.com, Site dahilinde başka sitelere link verebilir. Napolyon.com, link vasıtasıyla erişilen sitelerin gizlilik uygulamaları ve içeriklerine yönelik herhangi bir sorumluluk taşımamaktadır. <br>
              <br>
              Kişisel bilgiler; kişi adı-soyadı, adresi, telefon numarası, e-posta adresi ve kullanıcıyı tanımlamaya yönelik her türlü bilgiyi içermekte olup, kısaca "Gizli Bilgiler" olarak anılacaktır. Napolyon.com, işbu gizlilik politikasında aksi belirtilmedikçe kişisel bilgilerden herhangi birini Napolyon.com'un işbirliği içinde olmadığı şirketlere ve üçüncü kişilere açıklamayacaktır. Aşağıda belirtilen sınırlı durumlarda Napolyon.com, işbu "Gizlilik Politikası" hükümleri dışında kullanıcılara ait bilgileri üçüncü kişilere açıklayabilir. Bu durumlar sınırlı sayıda olmak üzere;<br>
              <ul>
                <li>Yetkili hukuki otoriteler tarafından çıkarılan ve yürürlükte olan Kanun, Kanun Hükmünde Kararname, Yönetmelik v.b. gibi hukuk kurallarının getirdiği zorunluluklara uyulmasının;</li>
                <li>Napolyon.com'un kullanıcılarla akdettiği "Napolyon.com Üyelik Sözleşmesi"nin ve diğer sözleşmelerin gereklerinin yerine getirilmesi ve bunların uygulamaya konulmasının;</li>
                <li>Yetkili idari ve adli otorite tarafından usulüne göre yürütülen bir araştırma veya soruşturmanın yürütümü amacıyla kullanıcılarla ilgili bilgi talep edilmesinin ve</li>
                <li>
                  Kullanıcıların hakları veya güvenliklerini korumak için bilgi verilmesinin gerekli olduğu hallerdir.<br>
                </li>
              </ul>
              Napolyon.com, kişisel bilgileri kesinlikle özel ve gizli tutmayı, bunu bir sır saklama yükümü olarak addetmeyi ve gizliliğin sağlanması ve sürdürülmesi, gizli bilginin tamamının veya herhangi bir kısmının kamu alanına girmesini veya yetkisiz kullanımını veya üçüncü bir kişiye ifşasını önlemek için gerekli tüm tedbirleri almayı ve gerekli özeni göstermeyi taahhüt etmektedir. Napolyon.com'un gerekli bilgi güvenliği önlemlerini almasına karşın Site'ye ve sisteme yapılan saldırılar sonucunda gizli bilgilerin zarar görmesi veya üçüncü kişilerin eline geçmesi durumunda Napolyon.com'un herhangi bir sorumluluğu olmayacaktır. <br>
              <br>
              <strong>EK 2: Nap Puan Kullanım Koşulları </strong><br>
              <br>
              İşbu "EK-2 Nap Puan Kullanım Koşulları", Üye ile Napolyon.com arasında akdedilen "Napolyon.com Üyelik Sözleşmesi"ne ("Sözleşme") ek olarak tanzim edilmiş olup, Üye'nin Sözleşme kapsamında edindiği Nap Puanlar'ın kullanım koşullarını düzenlemektedir. <br>
              <br>
              <strong>Üyelik</strong><br>
              <br>
              Kişiler, Üyeler'den birinin davetini kabul ettikten sonra veya farklı kaynaklardan kendileri bizzat gelerek Site'deki "Üye Formu"nu doldurarak; Site'ye üye olabilirler. <br>
              <br>
              Site'ye üye olmak için kişiler Site'de yer alan "Üye Formu"nu dolduracak, "Üye Formu"nda belirtmiş oldukları e-posta adresine gönderilen aktivasyon E-postasındaki aktivasyon bağlantısına tıklayarak veya e-postaya cevap vererek e-posta adreslerini aktive ettikten sonra bu e-posta adresi ve yine "Üye Formu"nda belirtmiş oldukları şifre ile Site'ye giriş yapabilecekler. <br>
              <br>
              <br>
              <strong>Nap Puan Sistemi </strong><br>
              <br>
              Site'de Üyeler'in Nap Puan kazanım kanalları aşağıdaki şekilde sıralanmıştır: <br>
              <br>
              • Üye olma <br>
              <br>
              • Profil bilgilerini doldurma <br>
              <br>
              • Farklı kanallardan gönderilecek olan pazarlama mesajlarını okuma (e-posta, SMS, banner vb.) <br>
              <br>
              • Anketlere katılım <br>
              <br>
              • Yeni Üye getirme <br>
              <br>
              • Hedef olarak verilen aksiyonları gerçekleştirme (form doldurma, alışveriş yapma vb.) <br>
              <br>
              Napolyon.com dilediği zaman yukarıda belirtilenlere yeni Nap Puan kazanım kanalları ekleyebilir ya da bu kanallarda değişiklik yapabilir. <br>
              <br>
              <strong>Nap Puan karşılığı Üye'ye yapılacak olan ödemeler </strong><br>
              <br>
              Üye her bir 100.000 Nap Puan'a ulaşması halinde, 100 (yüz) TL'ye hak kazanacaktır. Üye'ye, işbu 100 TL, sadece 100.000 Nap Puan seviyesine ulaştığında ve bu seviyeye ulaşması kaydıyla ödenecek olup, Üye, üyeliğinin devamı sırasında veya üyeliği sona erdikten sonra hiçbir surette Nap Puan seviyeleri aralığında (her bir 100.000 Nap Puan aralığı) kalan Nap Puanlar'ına karşılık olarak, orantılı olarak kendisine bir ödeme yapılmasını talep edemez. Üye, bu durumda sadece alt seviyenin karşılığına gelen tutarı talep hakkını haizdir. Napolyon.com dilediği zaman yukarıda belirtilenlere göre 100.000 Nap Puan seviyesine ulaşma koşulunda değişiklik yapabilir, nakit ödeme uygulamasını kaldırabilir.<br>
              <br>
              <br>
              <strong>Nap Puanlar’ın Kullanım Süresi:</strong><br>
              <br>
              Napolyon.com’da bir takvim yılı boyunca kazanılmış olan Nap Puanlar iki takvim yılı sonra 31 Ocak günü itibarı ile silinmektedir. <br>

          </div>
        </div>
      </div>
    </div>
    <!-- questions section end -->

    <!-- Start Contact -->
    <t-up-footer></t-up-footer>
    <t-footer></t-footer>
  </div>

</template>

<script>
import Footer from "@/view/layouts/Footer";
import upFooter from "@/view/layouts/upFooter";
import Header from "@/view/layouts/Header";
import feather from 'feather-icons';
export default {
  name: "MemberShipAgreement",
  components: {
    't-footer': Footer,
    't-up-footer': upFooter,
    't-header': Header,
  },
  mounted() {
    window.scrollTo(0,0);
    feather.replace();
  },
}
</script>

<style scoped>

</style>